import * as Actions from '../constants/cartToggleConstants'
function toggleReducer(state = { close: false }, action) {
    switch (action.type) {
        case Actions.CART_TOGGLE_OPEN:
            return { ...state, close: false };
        case Actions.CART_TOGGLE_CLOSE:
            return { ...state, close: true };
        case Actions.CART_TOGGLE_RESET:
            return { ...state, close: false };
        default: return state;
    }
}

export default toggleReducer