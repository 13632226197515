import { CATEGORY_LIST_FAIL, CATEGORY_LIST_REQUEST, CATEGORY_LIST_SUCCESS } from '../constants/categoryConstants'
const categoryListReducer = (state = { categories: [] }, action) => {
    switch (action.type) {
        case CATEGORY_LIST_REQUEST:
            return { ...state, loading: true, fetched: true };
        case CATEGORY_LIST_SUCCESS:
            const _cats = action.payload || []
            return { loading: false, fetched: true, categories: state.categories.concat(_cats) };
        case CATEGORY_LIST_FAIL:
            return { ...state, loading: false, fetched: true, error: action.payload };
        default:
            return state;
    }
}
export {
    categoryListReducer,
};






