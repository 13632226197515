import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Colors from '../colors'

const styles = theme => ({
    root: {
        backgroundColor: Colors.colors.primary,
        color: 'white',
    },
    input: {
        display: 'none',
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing.unit,
    },

});

function ContainedButtons(props) {
    const { classes } = props;
    return (
        
            <Button className="button primary full-width" disabled>
                <CircularProgress className={classes.circularProgress} size={20} />
                <b style={{ color: 'white', fontSize: 15 }}>Loading... </b>
            </Button>
    );
}

export default withStyles(styles)(ContainedButtons);
