import * as ActionTypes from '../constants/utilConstants'

function utilReducer(state = { siginNextRoute: undefined, shippingNextRoute: undefined }, action) {
    switch (action.type) {
        case ActionTypes.SIGIN_NEXT_ROUTE:
            return { ...state, siginNextRoute: action.payload };
        case ActionTypes.SHIPPING_NEXT_ROUTE:
            return { ...state, shippingNextRoute: action.payload };
        default: return state;
    }
}

export default utilReducer