import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import CheckoutSteps from '../components/CheckoutSteps'
import LoadingButton from '../components/loadingButton';
import { removeAllCart } from '../actions/cartItemAction';
import { Card } from "@material-ui/core";
import Empty from '../components/emptyList';
import config from '../config'
import * as Colors from '../colors'
import { generateOrderNumber } from '../util'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
const ship = { address: '', country: '', city: '' }
const pay = { paymentMethod: '' }

function PlaceOrderScreen(props) {
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState(null);
  const [note, setNote] = useState('')
  const cart = useSelector(state => state.cartItem);
  const user = useSelector(state => state.userSignin)
  useEffect(() => {
    if (!user.userInfo) {
      props.history.push('/signin')
    }
  }, [user, props])
  const { name, phone } = user.userInfo ? user.userInfo : { name: '', phone: '' }
  const { items = [], shipping = ship, payment = pay } = cart;
  const dispatch = useDispatch()
  if (shipping === null) {
    props.history.push("/shipping");
  }
  if (!payment.paymentMethod) {
    props.history.push("/payment");
  }
  const checkoutHandler = async () => {
    setDisable(true)
    let totalAmount = 0, totalAddedTaxAmount = 0, totalNetAmount = 0, taxIds = [], totalEarnPoints = 0
    const _items = items.map((i, index) => {
      const { unit } = i
      totalAmount += i.totalAmount
      totalAddedTaxAmount += i.addedTaxAmount
      totalNetAmount += i.totalNetAmount
      if (Array.isArray(i.applicableTaxIds)) {
        taxIds = [...i.applicableTaxIds]
      }
      let itemPoint = 0
      if (i.unitPoints) {
        itemPoint = i.qty * i.unitPoints
      }
      totalEarnPoints = totalEarnPoints + itemPoint
      //toal amount => total amount value without taxes (qty * price)
      return {
        addedTaxAmount: 0,
        name: i.name,
        unitPoints: itemPoint,
        optionName: i.variationName,
        itemVariationId: i.variationId,
        price: i.price,
        photo: i.photo,
        netAmount: i.totalAmount,
        currency: 'MMK',
        inclusiveTaxAmount: 0,
        index: index,
        totalNetAmount: i.totalAmount,
        totalAmount: i.totalAmount,
        taxIds: i.applicableTaxIds || [],
        qty: { value: i.qty, precision: unit.precision, suffix: "" }
      }
    })
    const checkoutfunc = firebase.functions().httpsCallable('posCheckoutWeb');
    const result = await checkoutfunc({
      merchantId: config.merchantId,
      customer:
      {
        name: name,
        phone: phone,
        address: shipping.address,
        payment: payment.paymentMethod
      },
      detail: _items,
      other: {
        orderType: 'Web',
        paymentType: 'Cash',
        totalAddedTaxAmount: totalAddedTaxAmount,
        totalAmount: totalNetAmount,
        discount: {},
        orderStatus: 'Pending',
        earnPoints: totalEarnPoints,
        currency: 'MMK',
        totalInclusiveTaxAmount: 0,
        totalDiscountAmount: 0,
        totalNetAmount: totalAmount,
        orderId: generateOrderNumber(),
        taxIds: taxIds,
        notes: note,
      },
    });
    const checkoutResult = result.data;
    if (checkoutResult.success) {
      console.log('checkout success', checkoutResult)
      dispatch(removeAllCart())
      props.history.push('/thank')
    } else {
      setError(checkoutResult.message)
    }
  }
  let totalAmount = 0, totalTaxAmount = 0
  items.map(i => totalTaxAmount += i.addedTaxAmount)
  items.map(i => totalAmount += i.totalNetAmount)
  return <div>
    <CheckoutSteps step1 step2 step3 step4 ></CheckoutSteps>
    <div className="placeorder">
      <div className="placeorder-info">
        <Card>
          <h3>
            Shipping
          </h3>
          {
            shipping ?
              <div>
                {shipping.address}, {shipping.city},
          {shipping.country},
          </div> :
              <div />
          }

        </Card>
        <Card>
          <h3>Payment</h3>
          <div>
            Payment Method: {cart.payment.paymentMethod}
          </div>
        </Card>
        <Card>
          <ul className="cart-list-container">
            <li>
              <h3>
                Shopping Cart
          </h3>
              <div>
                Price
          </div>
            </li>
            {
              items.length === 0 ?
                <Empty />
                :
                items.map(item =>
                  <li>
                    <div className="cart-image">
                      <img src={item.photo} alt="product" />
                    </div>
                    <div className="cart-name" >
                      <div>
                        <Link to={"/thank"}>
                          {item.name}
                        </Link>

                      </div>
                      <div style={{ marginTop: '10px' }}>
                        <i style={{ color: Colors.colors.variation }}>{item.variationName}</i>
                      </div>
                      <div style={{ marginTop: '10px' }}>
                        Qty: {item.qty}
                      </div>
                    </div>
                    <div className="cart-price">
                      <b style={{ fontSize: 14, fontWeight: 'bold' }}>  {item.price} {item.currency}</b>
                    </div>
                  </li>
                )
            }
          </ul>
        </Card>
      </div>
      <Card className="placeorder-action">
        {error && <div style={{ color: 'red' }}> {error}</div>}
        <ul>
          <li>
            {
              disable ? <LoadingButton /> :
                <button
                  className="button primary full-width"
                  onClick={() => checkoutHandler()}
                  disabled={items.length <= 0} >
                  <b style={{ color: 'white' }}>
                    Checkout</b>
                </button>
            }

          </li>
          {
            Object.keys(config.shipping).length > 0 ? <>
              <li>
                <h3>Shipping Fees</h3>
              </li>
              <li>
                <div>Yangon</div>
                <div>Free</div>
              </li>
              <li>
                <div>
                  Mandalay
            </div>
                <div>
                  3000MMK
            </div>
              </li>
            </> :
              <div />
          }
          <li>
            <h5>Notes</h5>
          </li>
          <li style={{ width: '100%' }}>
            <TextareaAutosize
              aria-label="minimum height"
              rowsMin={3}
              onChange={(e) => setNote(e.target.value)}
              style={{ width: '100%' }}
              placeholder="notes" />
          </li>
          <li>
            <h3>Summary</h3>
          </li>
          <li>
            <div>Items</div>
            <div>{totalAmount - totalTaxAmount} MMK</div>
          </li>
          {/* <li>
            <div>Shipping</div>
            <div>{shippingAmount} MMK</div>
          </li> */}
          <li>
            <div>Tax</div>
            <div>{totalTaxAmount} MMK</div>
          </li>
          <li>
            <div>Total</div>
            <div>{totalAmount}MMK</div>
          </li>
        </ul>
      </Card>
    </div>
  </div>

}

export default PlaceOrderScreen;