import * as Actions from '../constants/toggleConstants'
function toggleReducer(state = { open: false, itemId: null }, action) {
    switch (action.type) {
        case Actions.OPEN_BOTTOM_TOGGLE:
            return { ...state, open: true, itemId: action.payload };
        case Actions.CLOSE_BOTTOM_TOGGLE:
            return { ...state, open: false, itemId: null };
        default: return state;
    }
}

export default toggleReducer