import { CATEGORY_LIST_FAIL, CATEGORY_LIST_REQUEST, CATEGORY_LIST_SUCCESS } from '../constants/categoryConstants'
import config from '../config'
import axios from 'axios';

const listCategory = () => async (dispatch) => {
    try {
        dispatch({ type: CATEGORY_LIST_REQUEST });
        const { data } = await axios.get(`${config.posUrl}/getAllCategory/${config.merchantId}`);
        dispatch({ type: CATEGORY_LIST_SUCCESS, payload: data.data });
    } catch (error) {
        dispatch({ type: CATEGORY_LIST_FAIL, payload: error.message });
    }
};
export {
    listCategory,
};
