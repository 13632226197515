import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { saveShipping } from '../actions/cartItemAction';
import CheckoutSteps from '../components/CheckoutSteps';

function ShippingScreen(props) {
  const userSignin = useSelector((state) => state.userSignin);
  const userShipping = useSelector((state) => state.cartItem);
  const utilState = useSelector((state) => state.util);
  const { shipping } = userShipping
  const { userInfo } = userSignin;
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [postalCode] = useState('');
  const [country] = useState('Myanmar');
  const { shippingNextRoute } = utilState
  const redirect = shippingNextRoute ? shippingNextRoute : '/payment'
  const dispatch = useDispatch();
  useEffect(() => {
    if (!userInfo) {
      props.history.push('/signin?redirect=shipping')
    }
    if (shipping) {
      props.history.push('payment');
    }
  }, [props, userInfo, shipping])

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveShipping({ address, city, postalCode, country }));
    props.history.push(redirect);
  }
  return <div>
    <CheckoutSteps step1 step2 ></CheckoutSteps>
    <div className="form">
      <form onSubmit={submitHandler} >
        <ul className="form-container">
          <li>
            <h2>Shipping</h2>
          </li>

          <li>
            <label htmlFor="address">
              Address
          </label>
            <input type="text" name="address" id="address" onChange={(e) => setAddress(e.target.value)} required>
            </input>
          </li>
          <li>
            <label htmlFor="city">
              City
          </label>
            <input type="text" name="city" id="city" onChange={(e) => setCity(e.target.value)} required>
            </input>
          </li>
          {/* <li>
            <label htmlFor="postalCode">
              Postal Code
          </label>
            <input type="text" name="postalCode" id="postalCode" onChange={(e) => setPostalCode(e.target.value)}>
            </input>
          </li> */}
          {/* <li>
            <label htmlFor="country">
              Country
          </label>
            <input type="text" value={country} name="country" id="country" onChange={(e) => setCountry(e.target.value)} readOnly>
            </input>
          </li> */}
          <li>
            <button type="submit" className="button primary"><b style={{ color: 'white' }}>Continue </b></button>
          </li>

        </ul>
      </form>
    </div>
  </div>

}
export default ShippingScreen;