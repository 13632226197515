import {
    CART_ITEM_ADD,
    CART_ITEM_REMOVE,
    CART_ITEM_SAVE_SHIPPING,
    CART_ITEM_SAVE_PAYMENT,
    CART_ITEM_REMOVE_ALL,
    CART_ITEM_REMOVE_SHIPPING,
} from '../constants/cartItemConstants';
import * as Db from '../db'

const computeItem = (item = {}) => {
    let totalAmount = 0, totalNetAmount = 0, addedTaxAmount = 0
    let wholeTaxAmount = 0, inclusiveTaxAmount = 0
    const { taxes = [], qty = 0, price = 0, applicableTaxIds } = item
    totalAmount = price * qty
    let wholeTax = taxes.filter(tax => tax.application === 'whole')[0]
    if (wholeTax && wholeTax.value) {
        wholeTaxAmount = totalAmount * (wholeTax.value / 100)
    }
    if (applicableTaxIds !== null) {
        if (Array.isArray(applicableTaxIds)) {
            applicableTaxIds.forEach(taxId => {
                const inclusiveTax = taxes.filter(tax => tax.id === taxId)[0]
                inclusiveTaxAmount = totalAmount * (inclusiveTax.value / 100)
            })
        }
    }
    addedTaxAmount = wholeTaxAmount + inclusiveTaxAmount
    totalNetAmount = totalAmount + wholeTaxAmount + inclusiveTaxAmount
    return {
        ...item,
        totalAmount: totalAmount,
        totalNetAmount: totalNetAmount,
        addedTaxAmount: addedTaxAmount,
        displayAmount: ` ${totalAmount} + ${wholeTaxAmount} + ${inclusiveTaxAmount}`
    }

}
const cartItemReducer = (state = { items: [], shipping: {}, payment: {} }, action) => {
    switch (action.type) {
        case CART_ITEM_ADD:
            const item = computeItem(action.payload)
            const isExist = state.items.find(i => i.key === item.key);
            if (isExist) {
                var _addExistItems = state.items.map(i => i.key === item.key ? item : i)
                Db.setCartItems(_addExistItems)
                //setCartItemCookie(_addExistItems)
                return {
                    ...state,
                    items: _addExistItems
                };
            }
            Db.setCartItems([...state.items, item])
            // setCartItemCookie([...state.items, item])
            return { ...state, items: [...state.items, item] };
        case CART_ITEM_REMOVE:
            var _rmItems = state.items.filter(item => item.key !== action.payload)
            Db.setCartItems(_rmItems)
            // setCartItemCookie(_rmItems)
            return { ...state, items: _rmItems };
        case CART_ITEM_REMOVE_ALL:
            //removeCartItemCookie()
            return { ...state, items: [] };
        case CART_ITEM_SAVE_SHIPPING:
            Db.setShipping(action.payload)
            // setShipping(action.payload)
            return { ...state, shipping: action.payload };
        case CART_ITEM_REMOVE_SHIPPING:
            return { ...state, shipping: undefined }
        case CART_ITEM_SAVE_PAYMENT:
            return { ...state, payment: action.payload };
        default:
            return state;
    }
}
export {
    cartItemReducer,
};
