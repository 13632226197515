import * as Action from '../constants/cartToggleConstants'
export const openToggle = (itemId = null) => async (dispatch) => {
    dispatch({ type: Action.CART_TOGGLE_OPEN, payload: itemId });
}
export const closeToggle = () => async (dispatch) => {
    dispatch({ type: Action.CART_TOGGLE_CLOSE });
}
export const resetToggle = () => async (dispatch) => {
    dispatch({ type: Action.CART_TOGGLE_RESET });
}
