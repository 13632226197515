import { USER_SIGNIN_REQUEST, USER_SIGNIN_SUCCESS, USER_SIGNIN_FAIL, USER_REGISTER_REQUEST, USER_REGISTER_SUCCESS, USER_REGISTER_FAIL, USER_LOGOUT, USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAIL } from "../constants/userConstants";
import {
  PHONE_VERIFY_FAIL,
  PHONE_VERIFY_SUCCESS,
  PHONE_VERIFY_REQUEST,
  PHONE_VERIFY_CLEAR,
} from '../constants/phonVerifyConstants'
import {
  PHONE_AUTH_FAILED,
  PHONE_AUTH_REQUEST,
  PHONE_AUTH_SUCCESS,
  PHONE_AUTH_CLEAN
} from '../constants/phoneAuthConstants'

function userSigninReducer(state = {}, action) {
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
      return { loading: true };
    case USER_SIGNIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_SIGNIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
}

function userUpdateReducer(state = {}, action) {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function userRegisterReducer(state = {}, action) {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}
function phoneAuthReducer(state = {}, action) {
  switch (action.type) {
    case PHONE_AUTH_REQUEST:
      return { loading: true };
    case PHONE_AUTH_SUCCESS:
      return { loading: false, authInfo: action.payload };
    case PHONE_AUTH_FAILED:
      return { loading: false, error: action.payload };
    case PHONE_AUTH_CLEAN:
      return {}
    default: return state;
  }
}
function phoneVerifyReducer(state = {}, action) {
  switch (action.type) {
    case PHONE_VERIFY_REQUEST:
      return { loading: true, success: false };
    case PHONE_VERIFY_SUCCESS:
      return { loading: false, success: true };
    case PHONE_VERIFY_CLEAR:
      return {};
    case PHONE_VERIFY_FAIL:
      return { loading: false, error: action.payload, success: false };
    default: return state;
  }
}
export {
  userSigninReducer, userRegisterReducer, userUpdateReducer, phoneAuthReducer, phoneVerifyReducer
}