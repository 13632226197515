import * as React from 'react'
import { ShoppingBasket } from "@material-ui/icons";
function EmptyItem() {
    return (
        <div style={{
            position: 'relative',
            backgroundSize: 'cover',
            width: '200px',
            height: '100px'
        }}>
            <ShoppingBasket 
            style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }} />
        </div>
    )
}
export default EmptyItem