import * as React from 'react';
import { removeFromCart, addToCart } from "../actions/cartItemAction";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Empty from '../components/emptyList';
import * as Colors from '../colors'
import * as CartToggleActions from '../actions/cartToggleActions'
import * as UtilActions from '../actions/utilActions'
function ItemCartScreen(props) {
    const { items = [] } = useSelector(state => state.cartItem);
    let totalCount = 0, currency = 'MMK', totalAmount = 0
    items.map(i => totalCount += parseInt(i.qty, 10))
    items.map(i => totalAmount += i.totalNetAmount)
    items.map(i => currency = i.currency)
    const dispatch = useDispatch();
    const removeFromCartHandler = (productId) => {
        dispatch(removeFromCart(productId));
    }
    const addToCartHandler = (item, qty) => {
        dispatch(addToCart({ ...item, qty: qty }))
    }
    const toggleHandler = () => {
        dispatch(CartToggleActions.closeToggle())
        dispatch(UtilActions.SinginNextRoute('/shipping'))
        dispatch(UtilActions.ShippingNextRoute(undefined))
    }
    return <div className="cart" key='1'>
        <div className="cart-list" key='2'>
            <ul className="cart-list-container">
                <li>
                    <h3>
                        Shopping Cart
                     </h3>
                    <div key='3'>
                        Price
                     </div>
                </li>
                {
                    items.length === 0 ?
                        <Empty />
                        :
                        items.map((item, index) =>
                            <li key={`41${index}`}>

                                <div className="cart-image" key={`40 ${index}`}>
                                    <img src={item.photo} alt="product" />

                                </div>
                                <div className="cart-name" key={`41 ${index}`}>
                                    <div key={`42 ${index}`}>
                                        {/* <Link to={"/item/" + item.key}> */}
                                            <b style={{ color: Colors.colors.primary }}> {item.name}</b>
                                        {/* </Link> */}

                                    </div>
                                    <div key={`422 ${index}`}>
                                        <i style={{ color: Colors.colors.variation, marginTop: '10px' }}>{item.variationName}</i>

                                    </div>
                                    <div key={`43 ${index}`} style={{ marginTop: '16px' }}>
                                        <b style={{ fontWeight: 'bold', fontSize: 14 }}> Qty:</b>
                                        <select style={{ marginLeft: '5px' }}
                                            value={item.qty}
                                            onChange={(e) => addToCartHandler(item, e.target.value)}>
                                            {[...Array(item.stockQty).keys()].map(x =>
                                                <option key={x + 1} value={x + 1}>{x + 1}</option>
                                            )}
                                        </select>
                                    </div>
                                    {/* <div key={`443${index}`} style={{ marginTop: '10px' }}>
                                        <i style={{ color: Colors.colors.variation }}>{item.displayAmount}</i>
                                    </div> */}
                                </div>
                                <div className="cart-price" key={`44 ${index}`}>
                                    <button type='button'
                                        style={{ color: 'black', backgroundColor: 'white', borderRadius: '40%', marginLeft: '5px', borderColor: 'white' }}
                                        onClick={() => removeFromCartHandler(item.key)}
                                    > X </button>
                                    <b style={{ fontSize: 19, fontWeight: 'bold' }}> {item.totalAmount + '' + item.currency}</b>
                                </div>
                            </li>
                        )
                }
            </ul>

        </div>
        <div className="cart-action" key='7'
            style={{ backgroundColor: 'white', marginTop: '0px' }}
        >
            <h4 style={{ fontSize: 15, fontWeight: 'bold', textAlign: 'center' }}>
                Subtotal ( {totalCount} items)
        :
        <i style={{ color: 'black' }}>  {totalAmount} </i> {currency}
            </h4>
            <Link to='/shipping'>
                <button
                    onClick={toggleHandler}
                    className="button full-width"
                    disabled={items.length === 0}
                    style={{ backgroundColor: Colors.colors.primary, color: 'white', }}
                >
                    Proceed to Checkout
              </button>
            </Link>

        </div>

    </div >
}

export default ItemCartScreen;