import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { phoneAuth } from '../actions/phoneAuthAction';
import { SiginCheckOutSteps } from '../components/CheckoutSteps';
import LoadingButton from '../components/loadingButton';

function SigninScreen(props) {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [authError, setAutError] = useState(null)
  const [authLoading, setAuthLoading] = useState(false)
  const userSignin = useSelector(state => state.userSignin);
  const phoneAuthInfo = useSelector(state => state.phoneAuth);
  const { userInfo } = userSignin;
  const dispatch = useDispatch();
  const redirect = props.location.search ? props.location.search.split("=")[1] : '/';
  useEffect(() => {
    if (userInfo) {
      props.history.push(redirect);
    } else {
      if (phoneAuthInfo && phoneAuthInfo.authInfo) {
        props.history.push('/otp')
      }
      if (phoneAuthInfo && phoneAuthInfo.error) {
        setAutError(phoneAuthInfo.error)
      }
      if (phoneAuthInfo) {
        setAuthLoading(phoneAuthInfo.loading)
      }
    }
  }, [props, redirect, userInfo, phoneAuthInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(phoneAuth(name, phone));
    props.history.push('/otp')
  }
  return <div>
    <SiginCheckOutSteps step1></SiginCheckOutSteps>
    <div className="form">
      <form onSubmit={submitHandler} >
        <ul className="form-container">
          <li>
            <h2>Sign-In</h2>
          </li>
          <li>
            {authError && <div>{authError}</div>}
          </li>
          <li>
            <label htmlFor="name">
              Name
          </label>
            <input type="name" name="name" id="name" onChange={(e) => setName(e.target.value)} required>
            </input>
          </li>
          <li>
            <label htmlFor="phone">Phone</label>
            <input type="phone" id="phone" name="phone" placeholder="09xxxxxxxx" pattern="[0]{1}[9]{1}[0-9]{7,9}" onChange={(e) => setPhone(e.target.value)} required>
            </input>
          </li>
          <li>
            {
              authLoading ?
                <LoadingButton /> :
                <button type="submit" className="button primary" disabled={authLoading}>
                  <b style={{ color: 'white' }}>
                    Next
                  </b>
                </button>
            }

          </li>
          {/* <li>
            New ?
        </li>
          <li>
            <Link to={redirect === "/" ? "register" : "register?redirect=" + redirect} className="button secondary text-center" >Create your account</Link>
          </li> */}
        </ul>
      </form>
    </div>
  </div>
}
export default SigninScreen;