export const isMobile = () => {
    return window.matchMedia("(max-width: 800px)").matches || false
}
const openCart = () => {
    if (isMobile()) {
        if (document.querySelector('.main') !== null)
            document.querySelector('.main').style.display = 'none'
    }
    if (document.querySelector('.serachFilter') !== null) document.querySelector('.serachFilter').classList.add('open');
    document.querySelector('.cartSidebar').style.transform = 'translateY(0)'
    document.querySelector('.main').style.marginRight = '320px'
    document.querySelector('.cartSidebar').classList.add('open');
};
const closeCart = () => {
    if (document.querySelector('.serachFilter') !== null) document.querySelector('.serachFilter').classList.remove('open');
    document.querySelector('.cartSidebar').style.transform = 'translateX(-100rem)'
    document.querySelector('.cartSidebar').style.transition = 'width 2s'
    document.querySelector('.main').style.marginRight = '0'
    document.querySelector('.main').style.display = 'block'
    document.querySelector('.cartSidebar').classList.remove('open');
};
const openMenu = () => {
    document.querySelector('.sidebar').classList.add('open');
};
const closeMenu = () => {
    document.querySelector('.sidebar').classList.remove('open');
};
export { openCart, openMenu, closeCart, closeMenu }