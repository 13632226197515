import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { listItem, addItemCategory } from '../actions/ItemAction';
import { addToCart } from '../actions/cartItemAction'
import Loading from '../components/loading';
import * as Colors from '../colors';
import { openToggle } from '../actions/toogleActions'
import { useAlert } from "react-alert";
import { isMobile } from "../css/functions";
//import Popup from '../components/popup'
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button, Typography, Grid, Box, Fab, Paper } from '@material-ui/core';
const mobile = isMobile()
const useStyles = makeStyles({
    root: { flexGrow: 1 },
    card: {
        maxWidth: 240,
        boxShadow: `0 14px 28px white, 0 10px 10px #dadbe6;`,
        borderStyle: 'none',
    },
});
function HomeScreen2(props) {
    const alert = useAlert()
    const classes = useStyles();
    const [pending, setPending] = useState(false)
    // const [isOpen, setIsOpen] = useState(false);
    // const [item, setItem] = useState(null)
    const itemList = useSelector((state) => state.itemList);
    const { categories = [] } = useSelector((state) => state.categoryList)
    const { items, loading, error, fetched } = itemList;
    const cloneItems = [...items]
    const dispatch = useDispatch();
    useEffect(() => {
        if (!fetched && !pending) {
            setPending(true)
            dispatch(listItem());
        }
        const { categoryId } = props.match.params
        if (categoryId !== undefined) {
            dispatch(addItemCategory(categoryId))
        }
    }, [dispatch, fetched, loading, props, pending, setPending]);
    const handleAddToCart = (item = {}) => {
        dispatch(addToCart({ ...item }))
        alert.success("Added To Cart", { color: 'blue' })
    };
    const handleToggleVariation = (itemId) => {
        dispatch(openToggle(itemId))
    }
    // const popupHandler = (item) => {
    //     setItem(item)
    //     setIsOpen(!isOpen)
    // }
    // const togglePopup = () => {
    //     setIsOpen(!isOpen);
    // }
    // const popupAddToCartHandler = (item) => {
    //     item.twoPrice ? handleToggleVariation(item._id) : handleAddToCart({ ...item, qty: 1 })
    //     setIsOpen(!isOpen)
    // }
    return (
        <div>
            {loading ? (
                <Loading />
            ) : error ? (
                <div>{error}</div>
            ) :
                    (
                        <div className='list' style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '2rem'
                        }}>

                            <ul className="cart-list-container">
                                <li style={{ borderBottomColor: 'white' }}>
                                    <nav >
                                        {
                                            categories.map((c, index) =>
                                                <Link
                                                    key={index}
                                                    style={{
                                                        marginLeft: '10px',
                                                        marginTop: mobile ? '5px' : '10px',
                                                    }}
                                                    to={`/home/${c.id}`}>
                                                    <Fab variant="extended"
                                                        size={mobile ? 'small' : 'medium'}
                                                        style={{
                                                            marginTop: 10,
                                                            background: Colors.colors.primary,
                                                            size: mobile && 13,
                                                            color: 'white',
                                                            fontSize: 13,
                                                            fontWeight: 'bold'
                                                        }}>
                                                        {c.name}
                                                    </Fab>
                                                </Link>)
                                        }
                                    </nav>
                                </li>

                                {
                                    cloneItems.length === 0 ?
                                        <div style={{ alignItems: 'center', margin: 0 }}>
                                            <Card>
                                                <Paper>
                                                    Empty Item
                                                </Paper>
                                            </Card>
                                        </div>
                                        :
                                        <div className={classes.root}>
                                            <Grid container spacing={4}>
                                                {
                                                    cloneItems.map(item =>
                                                        <Grid item xs={6} sm={3}>
                                                            <Card className={classes.card}>

                                                                {/* <CardActionArea onClick={() => popupHandler(item)}> */}
                                                                <CardActionArea>
                                                                    <CardMedia
                                                                        component="img"
                                                                        alt="Contemplative Reptile"
                                                                        height="140"
                                                                        image={item.photo}
                                                                        title="Contemplative Reptile"
                                                                        style={{
                                                                            objectFit: 'contain'
                                                                        }}
                                                                    />
                                                                    <CardContent style={{ height: '100px' }}>
                                                                        <Typography
                                                                            gutterBottom
                                                                            className='itemName'
                                                                            variant="h5"
                                                                            component="h2"
                                                                            style={{ fontWeight: 'bold', fontSize: mobile && 14 }}
                                                                        >
                                                                            {item.name}
                                                                        </Typography>
                                                                        < Box
                                                                            className='itemDescription'
                                                                            width={1}
                                                                            bgcolor="white"
                                                                            height={mobile ? 30 : 50}
                                                                            p={1}
                                                                            my={0.5}
                                                                            style={{
                                                                                color: 'black',
                                                                                fontWeight: 'bold',
                                                                                overflow: 'hidden',
                                                                                fontSize: mobile ? 8 : 12,
                                                                            }}
                                                                        >
                                                                            {item.description && item.description}

                                                                        </Box>

                                                                        <Typography
                                                                            className='itemPrice'
                                                                            gutterBottom
                                                                            variant="h4"
                                                                            component="h5"
                                                                            style={{
                                                                                textAlign: 'center',
                                                                                fontWeight: 'bold',
                                                                                fontSize: mobile && 14,
                                                                            }}>
                                                                            {item.price} MMK
                                                                           </Typography>
                                                                    </CardContent>
                                                                </CardActionArea>
                                                                <CardActions style={{ justifyContent: 'center', top: 0 }}>
                                                                    {
                                                                        item.twoPrice ?
                                                                            <Button
                                                                                size="small"
                                                                                color="primary"
                                                                                // className='button full-width'
                                                                                className='addButton'
                                                                                style={{ backgroundColor: Colors.colors.primary, color: 'white', height: '35px', fontSize: 15 }}
                                                                                onClick={() => handleToggleVariation(item._id)}
                                                                            >
                                                                                ADD TO CART
                                                                             </Button> :
                                                                            item.stockQty > 0 && item.price > 0 ?
                                                                                <Button
                                                                                    size="small"
                                                                                    color="primary"
                                                                                    //className='button full-width'
                                                                                    className='addButton'
                                                                                    style={{ backgroundColor: Colors.colors.primary, color: 'white', height: '35px', fontSize: 15 }}
                                                                                    onClick={() => handleAddToCart({ ...item, qty: 1 })}
                                                                                >
                                                                                    ADD TO CART
                                                                               </Button>
                                                                                :
                                                                                <Button
                                                                                    size="small"
                                                                                    // variant="contained"
                                                                                    className='addButton'
                                                                                    // className='button full-width'
                                                                                    style={{
                                                                                        backgroundColor: Colors.colors.primary,
                                                                                        color: 'white',
                                                                                        height: '35px',
                                                                                        fontSize: 15
                                                                                    }}
                                                                                    onClick={() => { }}
                                                                                    disabled
                                                                                >
                                                                                    <strike>Out Of Stock</strike>
                                                                                </Button>
                                                                    }
                                                                </CardActions>
                                                            </Card>

                                                        </Grid>
                                                    )
                                                }

                                            </Grid>
                                        </div>
                                }

                            </ul>
                        </div>
                    )
            }
            {/* {isOpen && <Popup
                content={<>
                    <h2>{item.name}</h2>
                    <div style={{ display: 'flex' }}>
                        <h3>{item.variationName}</h3>
                        <b style={{ marginLeft: 'auto' }}><h4>{item.price} MMK</h4></b>
                    </div>
                    <Carousel views={[{ source: item.photo }]} />
                    <button className='button primary full-width'
                        style={{ color: 'whitesmoke' }}
                        onClick={() => popupAddToCartHandler(item)}
                    >ADD TO CART</button>
                </>}
                handleClose={togglePopup}
            />} */}
        </div >
    );
}
export default HomeScreen2;
