import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { SiginCheckOutSteps } from '../components/CheckoutSteps';
import { phoneVerify } from "../actions/phoneVerifyAction";
import { signin } from '../actions/userActions';
import LoadingButton from '../components/loadingButton';
import { phoneAuthClean } from '../actions/phoneAuthAction'
function OTPScreen(props) {
    const [otp, setOTP] = useState('');
    const userSignin = useSelector(state => state.userSignin);
    const utilState = useSelector(state => state.util);
    const phoneAuthInfo = useSelector(state => state.phoneAuth);
    const phoneVerifyInfo = useSelector(state => state.phoneVerify);
    const { loading, error, success } = phoneVerifyInfo
    const { authInfo } = phoneAuthInfo
    const { userInfo } = userSignin;
    const { siginNextRoute } = utilState
    const redirect = siginNextRoute ? siginNextRoute : '/';
    const dispatch = useDispatch();
    useEffect(() => {
        if (userInfo && success) {
            props.history.push(redirect);
        }
        if (!authInfo) {
            props.history.push('/signin')
        }
        if (authInfo && success) {
            dispatch(signin(authInfo.name, authInfo.phone))
        }
    }, [props, dispatch, redirect, userInfo, authInfo, success]);
    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(phoneVerify(authInfo.id, otp))
    };
    const didnotHandler = () => {
        dispatch(phoneAuthClean())
    }
    return (
        <div>
            <SiginCheckOutSteps step1 step2 ></SiginCheckOutSteps>
            <div className="form">
                <form onSubmit={submitHandler}>
                    <ul className="form-container">
                        <li>
                            <h2 style={{ alignItems: 'center' }}>OTP Confirmation</h2>
                        </li>
                        <li>
                            {error && <div>{error}</div>}
                        </li>
                        <li>
                            <input type="text" id="otp" name="otp" pattern="[0-9]{4}" placeholder='xxxx' onChange={(e) => setOTP(e.target.value)} required>
                            </input>
                        </li>
                        <li style={{ margin: '0 auto' }} onClick={didnotHandler}>
                            <Link to='/signin' ><b style={{ fontSize: 14, fontWeight: 'bold' }}>Resend OTP </b></Link>
                        </li>
                        <li>
                            {
                                loading ?
                                    <LoadingButton /> :
                                    <button type="submit" className="button primary" disabled={loading}>
                                        <b style={{ color: 'white' }}>  Continue</b>
                                    </button>
                            }

                        </li>
                    </ul>
                </form>
            </div>
        </div>
    );
}
export default OTPScreen;
