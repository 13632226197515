import Axios from "axios";
import config from '../config'
import {
    PHONE_AUTH_REQUEST,
    PHONE_AUTH_SUCCESS,
    PHONE_AUTH_FAILED,
    PHONE_AUTH_CLEAN,
} from "../constants/phoneAuthConstants";
const phoneAuth = (name, phone) => async (dispatch) => {
    dispatch({ type: PHONE_AUTH_REQUEST, payload: { name, phone } });
    try {
        const { data } = await Axios.get(`${config.posUrl}/phoneAuth/${config.merchantId}/${phone}`);
        if (data && data.status !== 'error') {
            dispatch({ type: PHONE_AUTH_SUCCESS, payload: { name: name, phone: phone, id: data.id } });
        } else {
            dispatch({ type: PHONE_AUTH_FAILED, payload: data && data.message });
        }
    } catch (error) {
        dispatch({ type: PHONE_AUTH_FAILED, payload: error.message });
    }
}
const phoneAuthClean = () => (dispatch) => {
    dispatch({ type: PHONE_AUTH_CLEAN, });
}
export { phoneAuth, phoneAuthClean };