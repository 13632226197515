import localforage from 'localforage'
import config from './config';
localforage.config({
    driver: localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
    name: config.db.name,
    version: 1.0,
    size: 4980736,
    storeName: config.db.storeName, // Should be alphanumeric, with underscores.
    description: config.db.description
});
export const DB_USER_KEY = 'DBUSERKEY'
export const DB_CART_ITEM_KEY = 'DBCARTITEMKEY'
export const DB_SHIPPING_KEY = 'DBSHIPPINGKEY'
export const setUser = (obj = {}) => {
    localforage.setItem(DB_USER_KEY, { ...obj }, (err, v) => {
        if (err) {
            console.log('db: set user [error]', err)
        } else {
            console.log('db: set user [success]', v)
        }
    })
}
export const getUser = async () => {
    return await localforage.getItem(DB_USER_KEY)
}
export const removeUser = async () => {
    return await localforage.removeItem(DB_USER_KEY)
}
export const setCartItems = (items = []) => {
    localforage.setItem(DB_CART_ITEM_KEY, [...items], (err, v) => {
        if (err) {
            console.log('db: set items [error]', err)
        } else {
            console.log('db: set items [success]', v)
        }
    })
}
export const getCartItems = async () => {
    return await localforage.getItem(DB_CART_ITEM_KEY)
}
export const removeCartItems = async () => {
    return await localforage.removeItem(DB_CART_ITEM_KEY)
}
export const setShipping = (obj = {}) => {
    localforage.setItem(DB_SHIPPING_KEY, { ...obj }, (err, v) => {
        if (err) {
            console.log('db: set items [error]', err)
        } else {
            console.log('db: set items [success]', v)
        }
    })
}
export const removeShipping = async () => {
    return await localforage.removeItem(DB_SHIPPING_KEY)
}
export const getShipping = async () => {
    return await localforage.getItem(DB_SHIPPING_KEY)
}
export const clean = async () => {
    return await localforage.clear()
}