import {
    ITEM_LIST_REQUEST,
    ITEM_LIST_SUCESS,
    ITEM_LIST_FAIL,
    ITEM_LIST_SEARCH,
    ITEM_LIST_SERACH_CLEAR,
    ITEM_LIST_ADD_CATEGORY
} from '../constants/itemConstants';
import config from "../config";
import { FETCH_ITEM_FAIL, FETCH_ITEM_REQUEST, FETCH_ITEM_SUCCESS } from '../constants/itemConstants'
const itemListFormat = (items = [], variations = [], modifiers = [], taxes = []) => {
    let key = 0, data = [], stateItems = []
    items.forEach(item => {
        let { category, name, itemVariationIds = [], applicableTaxIds = [], id, photo, description, defaultVariation } = item
        const { enableInventoryControl = false } = defaultVariation
        const [defaultId, defautlPrice] = [defaultVariation.id, defaultVariation.price]
        photo = photo || config.defaultPhoto
        const _list = itemVariationIds.map(varId => {
            var _variation = variations.filter(v => v.id === varId)[0]
            if (_variation) {
                let { name, price, currency, stockQty, unit, points = 0 } = _variation
                if (!enableInventoryControl && (varId === defaultId) && defautlPrice > 0) {
                    // make unlimit to default limit
                    stockQty = 20
                }
                return {
                    variationName: name,
                    variationId: varId,
                    photo: _variation.photo || photo,
                    applicableTaxIds: applicableTaxIds,
                    taxes: [...taxes],
                    modifiers: [...modifiers],
                    price: price,
                    currency: currency,
                    stockQty: stockQty,
                    unitPoints: points === null ? 0 : points,
                    notValid: true,
                    unit: { ...unit }
                }
            } else {
                return {
                    notValid: false
                }
            }
        }).filter(i => i.notValid === true).map(i => {
            key++
            return {
                ...i,
                key: key,
                photo: i.photo,
                _id: id,
                name: name,
                description: description === null ? '' : description,
                category: category && category.name,
                categoryId: category && category.id,
            }
        })
        if (_list.length > 1) {
            stateItems.push({ ..._list[0], twoPrice: true, photo: photo })
        } else {
            if (_list.length === 1) stateItems.push({ ..._list[0], twoPrice: false })
        }
        data = data.concat([..._list])
    })
    return { origin: data, stateItems: stateItems }
}

const itemListReducer = (state = { items: [], stateItems: [], origin: [], categoryId: 'All' }, action) => {
    switch (action.type) {
        case ITEM_LIST_REQUEST:
            return { ...state, loading: true, fetched: false };
        case ITEM_LIST_SUCESS:
            const { items = [], variations = [], modifiers = [], taxes = [] } = action.payload
            const _formatState = itemListFormat(items, variations, modifiers, taxes)
            const _origin = _formatState.origin
            const _stateItems = _formatState.stateItems
            return { ...state, loading: false, fetched: true, items: _stateItems, origin: _origin, stateItems: _stateItems };
        case ITEM_LIST_FAIL:
            return { ...state, loading: false, fetched: true, error: action.payload };
        case ITEM_LIST_ADD_CATEGORY:
            const _catId = action.payload
            const _categoryItems = _catId !== 'All' ?
                state.stateItems.filter(item => item.categoryId === _catId) :
                state.stateItems
            return { ...state, categoryId: action.payload, items: _categoryItems }
        case ITEM_LIST_SEARCH:
            var { term } = action.payload
            term = term.replace(/\\$/, "")
            var search = new RegExp(term, 'i')
            const _serchItems = state.categoryId !== 'All' ?
                state.stateItems.filter(item => search.test(item.name) && item.categoryId === state.categoryId) :
                state.stateItems.filter(item => search.test(item.name))
            return { ...state, items: _serchItems }
        case ITEM_LIST_SERACH_CLEAR:
            const _cleanItems = state.categoryId !== 'All' ? state.stateItems.filter(item => item.categoryId === state.categoryId) : state.stateItems
            return { ...state, items: _cleanItems }
        default:
            return state;
    }
}
const fetchItemReducer = (state = { items: [] }, action) => {
    switch (action.type) {
        case FETCH_ITEM_REQUEST:
            return { ...state, loading: true, fetched: false, error: null };
        case FETCH_ITEM_SUCCESS:
            return { loading: false, fetched: true, items: [...state.items, action.payload] };
        case FETCH_ITEM_FAIL:
            return { ...state, loading: false, fetched: false, error: action.payload };
        default:
            return state;
    }
}

export {
    itemListReducer,
    fetchItemReducer,
};






