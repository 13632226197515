import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import * as Alert from "react-alert";
import AlertTemplate from 'react-alert-template-basic'
import './css/index.css';
import App from './App';
import store from './store';
import './firebase';
import './db';
import './gtm';

const options = {
  offset: '40px',
  timeout: 3000,
  position: Alert.positions.MIDDLE,
  transition: Alert.transitions.SCALE
};

ReactDOM.render(
  <Provider store={store}>
    <Alert.Provider template={AlertTemplate} {...options}>
      <App />
    </Alert.Provider>
  </Provider>,
  document.getElementById('root'));

