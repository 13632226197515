import React, { useEffect } from 'react';
import './css/App.css';
import { BrowserRouter, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { listCategory } from "./actions/categoryActions";
import AppBar from './components/Appbar';
import config from './config'
import {
  ProductScreen,
  SigninScreen,
  ShippingScreen,
  RegisterScreen,
  ProfileScreen,
  ItemCartScreen,
  ItemScreen,
  PaymentScreen,
  HomeScreen,
  PlaceOrderScreen,
  OTPScreen,
  ThankYouScreen
} from "./screens";
function App(props) {
  const { fetched, loading } = useSelector((state) => state.categoryList)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!fetched && loading) {
      dispatch(listCategory())
    }
  }, [fetched,
    loading,
    dispatch,
    props,
  ])
  return (
    <BrowserRouter>
      <div className="grid-container">
        <main className="main">
          <div className="content">
            <Route path="/signin" component={SigninScreen} />
            <Route path="/register" component={RegisterScreen} />
            <Route path="/product/:id" component={ProductScreen} />
            <Route path="/item/:id" component={ItemScreen} />
            <Route path="/cart" component={ItemCartScreen} />
            <Route path="/shipping" component={ShippingScreen} />
            <Route path="/payment" component={PaymentScreen} />
            <Route path="/placeorder" component={PlaceOrderScreen} />
            <Route path="/profile" component={ProfileScreen} />
            <Route path='/home/:categoryId' component={HomeScreen} />
            <Route path="/otp" component={OTPScreen} />
            <Route path="/thank" component={ThankYouScreen} />
            <Route path="/" exact={true} component={HomeScreen} />
          </div>
        </main>
        <header className='header'>
          <AppBar />
        </header>
        <footer className="footer">
          <span>
            &copy;
            <a href={config.businessUrl} style={{ color: 'whitesmoke' }}>
              {config.businessName}.</a> 2021 All rights reserved.
          </span>
        </footer>
      </div>
    </BrowserRouter >
  );
}

export default App;
