import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Rating from '../components/Rating';
import * as Colors from '../colors'
import { addToCart } from '../actions/cartItemAction'
import { fetchItem } from '../actions/ItemAction'
import Loading from '../components/loading'
const initItem = { name: null, _id: null, price: 0, description: '', }
function ItemScreen(props) {
    const [qty, setQty] = useState(1);
    const [loading, setLoading] = useState(false)
    const [item, setItem] = useState({ ...initItem })
    const detailItems = useSelector((state) => state.items).items
    const { items } = useSelector((state) => state.itemList);
    const dispatch = useDispatch();
    useEffect(() => {
        const copyItems = [...items]
        const { id } = props.match.params
        const item = copyItems.filter(item => item.key === parseInt(id, 10))[0] || { ...initItem }
        const detailItem = detailItems.filter(i => i.id === item._id) || []
        if (detailItem.length > 0) {
            item.description = detailItem[0].description
            setItem(item)
            setLoading(true)
        } else {
            dispatch(fetchItem(item._id))
        }
    }, [props, items, setItem, detailItems, dispatch]);
    const handleAddToCart = () => {
        dispatch(addToCart({ ...item, qty: qty }))
        props.history.push('/')
    };
    if (!loading) {
        return <Loading />
    }
    return (
        <div>
            <div className="back-to-result">
                <Link to="/"> Back</Link>
            </div>
            <>
                <div className="details">
                    <div className="details-image">
                        <img src={item && item.photo} alt="item" />
                    </div>
                    <div className="details-info">
                        <ul>
                            <li>
                                <h4>{item && item.name}</h4>
                            </li>
                            <li>
                                <a href="#reviews">
                                    <Rating
                                        value={3}
                                        text={10 + ' reviews'}
                                    />
                                </a>
                            </li>
                            <li>
                                Price: <b>{item && item.currency + ' ' + item && item.price}</b>
                            </li>
                            <li>
                                Description:
                  <div>{item && item.description}</div>
                            </li>
                        </ul>
                    </div>
                    <div className="details-action">
                        <ul>
                            <li>Price: {item && item.price}</li>
                            <li>
                                Status:{' In stock'}
                                {/* {item && item.countInStock > 0 ? 'In Stock' : 'Unavailable.'} */}
                            </li>
                            <li>
                                Qty:{' '}
                                <select
                                    value={qty}
                                    onChange={(e) => {
                                        setQty(e.target.value);
                                    }}
                                >
                                    {[...Array(20).keys()].map((x) => (
                                        <option key={x + 1} value={x + 1}>
                                            {x + 1}
                                        </option>
                                    ))}
                                </select>
                            </li>
                            <li>
                                <button
                                    onClick={handleAddToCart}
                                    className="button full-width"
                                    style={{ backgroundColor: Colors.colors.primary, color: 'white' }}
                                >
                                    Add to Cart
                        </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        </div>
    );
}
export default ItemScreen;
