import React from 'react';
import { addToCart } from '../actions/cartItemAction'
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import * as Colors from '../colors';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button, Typography, Grid } from '@material-ui/core';
import { useAlert, positions } from "react-alert";
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        marginLeft: 12,
    },
    card: {
        maxWidth: 200,
    },
});
const VariationScreen = ({ items = [] }) => {
    const alert = useAlert()
    const classes = useStyles();
    const dispatch = useDispatch();
    const handleAddToCart = (item = {}) => {
        dispatch(addToCart({ ...item }))
        alert.success("Added To Cart", { color: 'blue', position: positions.TOP_CENTER })
    };
    return (
        <div className={classes.root}>
            {
                items.length > 0 ?

                    < Grid container spacing={4}>
                        {
                            items.map(item =>
                                <Grid item xs={6} sm={3}>
                                    <Card className={classes.card}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                alt="Contemplative Reptile"
                                                height="140"
                                                image={item.photo}
                                                title="Contemplative Reptile"
                                                style={{
                                                    objectFit: 'contain'
                                                }}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    {item.name}
                                                </Typography>
                                                <Typography gutterBottom variant="h5" component="h3" style={{ alignItems: 'center', justifyItems: 'center' }}>
                                                    {item.variationName}
                                                </Typography>
                                                <Typography gutterBottom variant="h4" component="h3" style={{ alignItems: 'center', justifyItems: 'center' }}>
                                                    {item.price} MMK
                                 </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions style={{ justifyContent: 'center' }}>
                                            {
                                                item.stockQty > 0 && item.price > 0 ?
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        className='button full-width'
                                                        style={{ backgroundColor: Colors.colors.primary, color: 'white', height: '35px', fontSize: 15 }}
                                                        onClick={() => handleAddToCart({ ...item, qty: 1 })}
                                                    >
                                                        ADD TO CART
                                               </Button>
                                                    :
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        className='button full-width'
                                                        style={{
                                                            backgroundColor: Colors.colors.primary,
                                                            color: 'white',
                                                            height: '35px',
                                                            fontSize: 15
                                                        }}
                                                        onClick={() => { }}
                                                        disabled
                                                    >
                                                        <strike>Out Of Stock</strike>
                                                    </Button>
                                            }
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )
                        }
                    </Grid> :
                    <div>
                        Empty Items
                    </div>
            }
        </div >
    )
}
export default VariationScreen;