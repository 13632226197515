import {
    CART_ITEM_ADD,
    CART_ITEM_REMOVE,
    CART_ITEM_SAVE_SHIPPING,
    CART_ITEM_SAVE_PAYMENT,
    CART_ITEM_REMOVE_ALL,
    CART_ITEM_REMOVE_SHIPPING,
} from '../constants/cartItemConstants';
import * as Db from '../db'
const addToCart = (item) => (dispatch) => {
    dispatch({ type: CART_ITEM_ADD, payload: { ...item } });

};
const removeFromCart = (id) => (dispatch) => {
    dispatch({ type: CART_ITEM_REMOVE, payload: id });

};
const removeAllCart = () => async dispatch => {
    await Db.removeCartItems()
    dispatch({ type: CART_ITEM_REMOVE_ALL });
}
const saveShipping = (data) => (dispatch) => {
    dispatch({ type: CART_ITEM_SAVE_SHIPPING, payload: data });
}
const removeShipping = () => (dispatch) => {
    dispatch({ type: CART_ITEM_REMOVE_SHIPPING });
}
const savePayment = (data) => (dispatch) => {
    dispatch({ type: CART_ITEM_SAVE_PAYMENT, payload: data });
}

export {
    addToCart,
    removeFromCart,
    savePayment,
    saveShipping,
    removeAllCart,
    removeShipping
};
