import { ITEM_LIST_SUCESS, ITEM_LIST_FAIL, ITEM_LIST_REQUEST, ITEM_LIST_SEARCH, ITEM_LIST_SERACH_CLEAR } from '../constants/itemConstants';
import { FETCH_ITEM_FAIL, FETCH_ITEM_SUCCESS } from '../constants/itemConstants'
import { ITEM_LIST_ADD_CATEGORY } from '../constants/itemConstants'
import config from '../config'
import axios from 'axios';

const listItem = () => async (dispatch) => {
    try {
        dispatch({ type: ITEM_LIST_REQUEST });
        const { data } = await axios.get(`${config.posUrl}/getAllItem/${config.merchantId}`);
        const variationData = await axios.get(`${config.posUrl}/getAllVariation/${config.merchantId}`);
        const modifierData = await axios.get(`${config.posUrl}/getAllModifier/${config.merchantId}`);
        const taxesData = await axios.get(`${config.posUrl}/getAllTax/${config.merchantId}`);
        var _payload = { items: [], variations: [], modifiers: [], taxes: [] }
        if (data.status !== 'error') {
            _payload = { ..._payload, items: data.data || [] }
        }
        if (variationData.data.status !== 'error') {
            _payload = { ..._payload, variations: variationData.data.data || [] }
        }
        if (modifierData.data.status !== 'error') {
            _payload = { ..._payload, modifiers: modifierData.data.data || [] }
        }
        if (taxesData.data.status !== 'error') {
            _payload = { ..._payload, taxes: taxesData.data.data || [] }
        }
        if (data.status !== 'error') {
            dispatch({ type: ITEM_LIST_SUCESS, payload: { ..._payload } });
        } else {
            dispatch({ type: ITEM_LIST_FAIL, payload: data.message });
        }

    } catch (error) {
        dispatch({ type: ITEM_LIST_FAIL, payload: error.message });
    }
};
const fetchItem = (itemId) => async (dispatch) => {
    try {
        // dispatch({ type: FETCH_ITEM_REQUEST });
        const { data } = await axios.get(`${config.posUrl}/getItem/${config.merchantId}/${itemId}`);
        if (data.status !== 'error') {
            dispatch({ type: FETCH_ITEM_SUCCESS, payload: data.data });
        } else {
            dispatch({ type: FETCH_ITEM_FAIL, payload: data.message });
        }

    } catch (error) {
        dispatch({ type: FETCH_ITEM_FAIL, payload: error.message });
    }
}
const serchItem = (term) => dispatch => {
    dispatch({
        type: ITEM_LIST_SEARCH,
        payload: {
            term: term
        }
    })
}
const searchItemClean = () => dispatch => {
    dispatch({ type: ITEM_LIST_SERACH_CLEAR })
}
const addItemCategory = (categoryId) => dispatch => {
    dispatch({ type: ITEM_LIST_ADD_CATEGORY, payload: categoryId })
}
export {
    listItem,
    serchItem,
    searchItemClean,
    fetchItem,
    addItemCategory
};
