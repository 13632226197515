export const ITEM_LIST_SUCESS = 'ITEM_LIST_SUCESS';
export const ITEM_LIST_FAIL = 'ITEM_LIST_FAIL';
export const ITEM_LIST_REQUEST = 'ITEM_LIST_REQUEST';
export const ITEM_LIST_SEARCH = 'ITEM_LIST_SEARCH';
export const ITEM_LIST_SERACH_CLEAR = 'ITEM_LIST_SERACH_CLEAR';
export const ITEM_LIST_ADD_CATEGORY = 'ITEM_LIST_ADD_CATEGORY';

// fetch each item

export const FETCH_ITEM_SUCCESS = 'FETCH_ITEM_SUCCESS';
export const FETCH_ITEM_FAIL = 'FETCH_ITEM_FAIL';
export const FETCH_ITEM_REQUEST = 'FETCH_ITEM_REQUEST'; 