import Axios from "axios";
import config from '../config'
import {
    PHONE_VERIFY_REQUEST,
    PHONE_VERIFY_SUCCESS,
    PHONE_VERIFY_CLEAR,
    PHONE_VERIFY_FAIL
} from "../constants/phonVerifyConstants";
const phoneVerify = (id, code) => async (dispatch) => {
    dispatch({ type: PHONE_VERIFY_REQUEST });
    try {
        const { data } = await Axios.get(`${config.posUrl}/phoneVerify/${id}/${code}`);
        if (data && data.status !== 'error') {
            dispatch({ type: PHONE_VERIFY_SUCCESS });
        } else {
            dispatch({ type: PHONE_VERIFY_FAIL, payload: data && data.message });
        }
    } catch (error) {
        dispatch({ type: PHONE_VERIFY_FAIL, payload: error.message });
    }
}
 const phoneVerifyClean = () => dispatch => {
    dispatch({ type: PHONE_VERIFY_CLEAR })
}
export { phoneVerify, phoneVerifyClean };