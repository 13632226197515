import React, { useState, useEffect } from 'react';
import { logout } from '../actions/userActions';
import { removeShipping } from '../actions/cartItemAction'
import { useDispatch, useSelector } from 'react-redux';
import { ExitToApp, Update } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import * as UtilActions from '../actions/utilActions'
import { phoneAuthClean } from "../actions/phoneAuthAction";
import { phoneVerifyClean } from '../actions/phoneVerifyAction'
import * as Db from '../db';
function ProfileScreen(props) {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const dispatch = useDispatch();

  const userSignin = useSelector(state => state.userSignin);
  const { userInfo } = userSignin;
  const handleLogout = () => {
    dispatch(logout());
    dispatch(phoneAuthClean())
    dispatch(phoneVerifyClean())
    props.history.push("/signin");
  }
  const handleUpdate = () => {
    dispatch(UtilActions.ShippingNextRoute('/'))
    dispatch(removeShipping())
    Db.removeShipping().then(v => {
      props.history.push('/shipping')
    })
  }
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(logout());
  }
  useEffect(() => {
    if (userInfo) {
      console.log(userInfo)
      setPhone(userInfo.phone);
      setName(userInfo.name);
    }
  }, [userInfo])

  return <div className="profile">
    <div className="profile-info">
      <div className="form">
        <form onSubmit={submitHandler} >
          <ul className="form-container">
            <li>
              <h2>User Profile</h2>
            </li>
            <li>
              <label htmlFor="name">
                Name
          </label>
              <input value={name} type="name" name="name" id="name" readOnly>
              </input>
            </li>
            <li>
              <label htmlFor="Phone">
                Phone
          </label>
              <input value={phone} type="phone" name="phone" id="phone" readOnly>
              </input>
            </li>
            {/* <li>
              <label htmlFor="password">Password</label>
              <input value={password} type="password" id="password" name="password" onChange={(e) => setPassword(e.target.value)}>
              </input>
            </li> */}

            {/* <li>
              <button type="submit" className="button primary">Update</button>
            </li> */}
            <li>
              <Button
                className="button primary full-width"
                variant="contained"
                onClick={handleLogout}
                startIcon={<ExitToApp style={{ color: 'whitesmoke' }} />}
              >
                <b style={{ color: 'whitesmoke', fontSize: 12, fontWeight: 'bold' }}>Logout</b>
              </Button>
            </li>
            <li>
              <Button
                className="button primary full-width"
                variant="contained"
                onClick={handleUpdate}
                startIcon={<Update style={{ color: 'whitesmoke' }} />}
              >
                <b style={{ color: 'whitesmoke', fontSize: 12, fontWeight: 'bold' }}>Update Info</b>
              </Button>
            </li>
          </ul>
        </form>
      </div>
    </div>
    {/* <div className="profile-orders content-margined">
      {
        loadingOrders ? <div>Loading...</div> :
          errorOrders ? <div>{errorOrders} </div> :
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>DATE</th>
                  <th>TOTAL</th>
                  <th>PAID</th>
                  <th>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {orders.map(order => <tr key={order._id}>
                  <td>{order._id}</td>
                  <td>{order.createdAt}</td>
                  <td>{order.totalPrice}</td>
                  <td>{order.isPaid}</td>
                  <td>
                    <Link to={"/order/" + order._id}>DETAILS</Link>
                  </td>
                </tr>)}
              </tbody>
            </table>
      }
    </div> */}
  </div>
}

export default ProfileScreen;