import React from 'react';
import * as Colors from '../colors'
import { CheckCircle } from '@material-ui/icons'
import { Link } from 'react-router-dom';

function ThanYou(props) {
    return <div className='thank_you'>
        <div className='profile-info' style={{ alignItems: 'center' }}>
            <div>
                <h3>Thank You !</h3>
            </div>
            <div style={{ display: 'grid', backgroundColor: 'none' }}>
                <CheckCircle style={{ width: 100, height: 100, color: Colors.colors.primary }} />
            </div>
            <div>
                <h4> Your order have been received.</h4>
            </div>
            <div>
                <Link to='/'>
                    Go Back
                </Link>
            </div>
        </div>
    </div>

}

export default ThanYou;